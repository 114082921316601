export const address_server = 'https://ernsta.smartbio.club/api/v1.0/'
export const address_server_short = 'https://ernsta.smartbio.club/'

export const sections = [
    {
        "id": "ae4fe0cf-1d04-44b7-81da-3d95dfe6fbb8",
        "name": "Образцы"
    },
    {
        "id": "9ac9e8a0-4258-4bd3-a3af-5538d0df59ad",
        "name": "Отчеты"
    },
    {
        "id": "63e5bf01-f54a-4bcf-9a9a-f87ce17c8693",
        "name": "Проекты"
    },
    {
        "id": "383a1551-8262-46c2-861b-6ef8f960f902",
        "name": "Справочники"
    },
    {
        "id": "eb8ff029-8255-498d-b2cd-9037c1fa1e11",
        "name": "Хранилище"
    },
    {
        "id": "9307119f-b072-4af3-a0fd-88c218613c66",
        "name": "Пользователи"
    }
]
// YMAPS API
export const ymaps_apikey = "f5d8f31e-def9-4993-a13a-e1f76246de9c"
// global
export const COLORS = {
    color_brand: '#01b454',
    color_hover: '#007700',
    color_hover2: '#1eea1e',
    text_primary_brand: '#009044',
    text_primary: '#13392b',
    text_secondary: '#59666d',
    bg_inactive: '#ccc',
    bg_primary: 'white',
    bg_secondary: '#d9e7eb',
}
