import React from 'react';
import {observer} from "mobx-react-lite";
import {getCatalogs, getMappingCatalog} from "../../../../utils/API/api_catalog";
import {getListBarcodes, getListPacks, getListUnits, getPack, getTypeSamples} from "../../../../utils/API/api_list";
import {getStorages} from "../../../../utils/API/api_storage";
import {getFreePacks} from "../../../../utils/API/api_pucks";
import dropStyles from "./workRightStorageAddMenuDropdownFirst.module.css";
import closeSVG from "../../../../svg/close-01.svg";
import deleteSvG from "../../../../svg/delete-01.svg";
import arrowSVG from "../../../../svg/arrow-01.svg";
import classes from "../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css";
import error from "../../../../svg/attention-01.svg";
import {address_server_short} from "../../../../config";
import Tree from "./Tree/tree";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import WidgetDateTimePicker from "../../../Widgets/WidgetDateTimePicker";
import MultiSelectBoxes from "../../../Widgets/MultiSelectBox";
import {Store} from "../../../../store/Store";
import {editSampleUpdatedVersion} from "../../../../utils/API/api_sample";
import BarcodesMultiList from "../../../Widgets/BarcodesMultiList";
import WorkRightAddMenuItem from "../../WorkRightManualDir/WorkRightAddMenu/workRightAddMenuItem";
import {useState} from "react";
import {toJS} from "mobx";

const AddSampleModal = observer(({
     mode,
     setVisibleAdd,
     setWidget,
     sizeX
}) => {
  const {
    clearSelectedSample,
    selectedSample,
    setSampleField,
    getPath,
    loadTree,
    updateSelectedTreeNodePacks
  } = React.useContext(Store);


  const [treeLocation, setTreeLocation] = React.useState([]);
  const [packs, setPacks] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [newUserFields, setNewUserFields] = useState({id: 0, fields: {}});
  const [nameError, setNameError] = React.useState(false);
  const [typeError, setTypeError] = React.useState(false);
  const [errorBarcode, setErrorBarcode] = React.useState(false);
  const [volumeError, setVolumeError] = React.useState(false);
  const [locationError, setLocationError] = React.useState(false);
  const [descError, setDescError] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [unitsList, setUnitsList] = React.useState([]);
  const [clickOnSave, setClickOnSave] = React.useState(false);
  const [isVisibleTypeDropdown, setIsVisibleTypeDropdown] = React.useState(false);
  const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = React.useState(false);
  const [patientDescriptions, setPatientDescriptions] = React.useState([]);
  const [catalogsDataSource, setCatalogsDataSource] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState();
  const [path, setPath] = React.useState('');
  const [selectedPack, setSelectedPack] = React.useState();
  React.useEffect(()=>{
    if (Array.isArray(selectedSample?.user_fields)){
      const newSelectItem = {...newUserFields};

      selectedSample.user_fields.map(val => {
        if(val.type.type_name == 'file')
          newSelectItem.fields[val.id_field] = { value: [], setting: JSON.parse(val.setting), files: val.value };
        else if(val.type.type_name == 'image')
          newSelectItem.fields[val.id_field] = { value: [], setting: JSON.parse(val.setting), files: val.value };
        else
        {
          try{
            newSelectItem.fields[val.id_field] = JSON.parse(val.value);
          }catch {
            newSelectItem.fields[val.id_field] = val.value;
          }
        }
      })
      setNewUserFields(newSelectItem)
    }
  }, [])

  React.useEffect(() => {
    if (selectedSample === null) {
      setVisibleAdd(false);
    }
  }, [selectedSample]);

  React.useEffect(() => {
    let findedType = types.find(i => i.id === selectedSample?.type);
    if (findedType) {
      setSelectedType(findedType);
    }
  }, [selectedSample?.type, types]);

  React.useEffect(() => {
    setPath(getPath(selectedSample?.storage));
  }, [selectedSample?.storage, treeLocation]);

  React.useEffect(() => {
    const loadPacksByStorageId = async () => {
      if (selectedSample?.storage === undefined || selectedSample?.storage === 0) {
        let freePacks = await  getFreePacks(mode.token);
        setPacks(freePacks);
      } else {
        let res = await getListPacks(mode.token, selectedSample?.storage);
        setPacks(res);
      }
    }
    loadPacksByStorageId();
  }, [selectedSample?.storage]);

  React.useEffect(() => {
    let findedPack = packs.find(i => i.id === selectedSample?.pack);

    if (findedPack) {
      setSelectedPack(findedPack);
    }
  }, [selectedSample, packs]);

  React.useEffect(() => {
    const getSamplesT = async () => {
      setUnitsList(await getListUnits());
      const loc = await getStorages(mode.token, false, '');
      loc.push({
        id: undefined,
        type: 1,
        name: "Глобальное хранилище",
        icon: "icons/location-01.svg",
        color: "#ad1d1d",
        favorite: false,
        level: "0",
        parent: 0,
        nodes: []
      });
      setTreeLocation(loc);
      setTypes(await getTypeSamples(mode.token));

      let res = await getCatalogs(mode.token);
      setCatalogsDataSource(res);

      let patient = JSON.parse(JSON.stringify(patientDescriptions));
      if (selectedSample?.patient_description) {
        for(let i=0; i< selectedSample.patient_description.length; i++) {
          let item = selectedSample.patient_description[i];
          const newCatalog = await getMappingCatalog(mode.token, item.catalog);
          let fields = item.fields.map(field => {
            return {id: field.field, value: field.value, setting: field.setting, files: field.files.map(i => i) ?? [] }});
          patient.push({
            id: item.catalog,
            fields: fields,
            catalog: newCatalog,
          });
          setPatientDescriptions(patient);
        }
      }
    }
    getSamplesT();
  }, []);

  React.useEffect(() => {
    if(selectedType){
      let files = [];
      for (let key in newUserFields.fields) {
        if(newUserFields.fields[key].files){
          files.push({key: `user_files[${key}]`, item: newUserFields[`files[${key}]`]?.filter(val => typeof val !== 'string')})
        }
      }
      if(files.length > 0)
        setSampleField('files', files);
    }
  }, [newUserFields])

  async function startSave() {

    setClickOnSave(true)
    //return;
    let errors = 0

    if (patientDescriptions.length > 0) {
      let catalogs = [];
      let patient = [];
      let filesCatalog = [];

      patientDescriptions.forEach(item => {
        const reqFieldsIds = []
        item?.catalog?.fields?.map(value => {
          if(value.required){
            reqFieldsIds.push(value.id)
          }
        })

        reqFieldsIds.forEach(id => {
          if(!item.fields?.find(val => val.id == id)){
            errors++
          }
          else {
            const findItem = item.fields.find(val => val.id == id);
            if(findItem.value == '' && findItem.files.length == 0 )
              errors++
          }
        });

        item.fields.forEach(i => {
          catalogs.push({ id: item.id });
          if (Object.keys(i.setting).length === 0){
            patient.push({
              id: i.id,
              value: i.value
            });
            if(i.files && i.files.length > 0)
              filesCatalog.push(...i.files.map(newFile => {
                return {key: `files[${i.id}]`, item: [newFile]}
              }))
          }
          else
          {
            patient.push(i);
            if(i.files && i.files.length > 0)
              filesCatalog.push(...i.files.map(newFile => {
                return {key: `files[${i.id}]`, item: [newFile]}
              }))
          }

        });

      });

      setSampleField('files', [...selectedSample.files ?? [], ...filesCatalog])


      setSampleField('catalogs', catalogs);
      setSampleField('patient_description', patient);

    }

    setSampleField('unit', selectedType?.unit);
    setSampleField('description', selectedSample?.description ?? selectedType?.description ??  '');

    let user_fields = [];

    if(selectedType) {
      if (newUserFields.fields) {
        selectedType.user_fields.forEach(item => {
          user_fields.push({id_field: item.id_field, value: ''})
        })

        for (let key in newUserFields.fields) {
          user_fields.map(item => {
            if (item.id_field == key) {
              if(newUserFields[key]?.files) {
                return item.value = newUserFields.fields[key].files.filter(val => typeof val === 'string')
              } else {
                return item.value = JSON.stringify(newUserFields.fields[key])
              }
            }
            return item;
          })
        }

        setSampleField('user_fields', JSON.stringify(user_fields));
      }
    }


    if(selectedType && newUserFields) {
      if (newUserFields.fields) {
        selectedType.user_fields.forEach(item => {
          if(item.required){
            if((newUserFields[`files[${item.id_field}]`] != undefined && newUserFields[`files[${item.id_field}]`].length > 0)){
              return
            }
            else if(!newUserFields.fields[item.id_field]){
              errors++;
            } else if(newUserFields.fields[item.id_field].value == '' && newUserFields.fields[item.id_field].files?.length == 0)
              errors++;
          }
        })
      }
    }

    if (!selectedSample?.volume && selectedSample?.volume === null || selectedSample?.volume <= 0) {
      setVolumeError(true)
      errors++;
    } else {
      setVolumeError(false)
    }

    if (!selectedSample.unit) {
      errors++;
    }

    if (!selectedSample.description) {
      setDescError(true)
      errors++;
    } else {
      setDescError(false)
    }

    if (!selectedSample.type) {
      setTypeError(true)
      errors++;
    } else {
      setTypeError(false)
    }

    if (selectedSample.storage !== undefined && selectedSample.storage !== null && selectedSample.storage !== 0) {
      if (!selectedSample.storage) {
        setLocationError(true)
        errors++;
      } else {
        setLocationError(false)
      }
    } else {
      selectedSample.storage = 0;
    }

    if (!selectedSample.date) {
      setDateError(true)
      errors++;
    } else {
      setDateError(false)
    }

    setSampleField('user_fields', JSON.stringify(JSON.parse(selectedSample?.user_fields)?.map(item => {
      if(item.value == ''){
        item.value = JSON.stringify({value:'', setting: {}})
        return item
      }else
        return item
    })))

    if (!errors) {
      if (!!selectedSample.id) {
        setWidget({
          status: 'Attention',
          text: 'Данные образца будут изменены',
          fun: async () => {
            const res = await editSampleUpdatedVersion(mode.token, selectedSample.id, selectedSample);
            if (res.success) {
              setWidget({
                status: 'Ok',
                text: 'Образец успешно сохранён!',
                fun: async () => {
                  mode.setVisibleAdd(false);
                  await loadTree(mode.token, false, '');
                  await updateSelectedTreeNodePacks(mode.token);
                  clearSelectedSample();
                  mode.setType({mode: 'view', item: ''});
                }
              });
            } else {
              setWidget({
                status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                fun: async () => { }
              });
            }
          }
        });
      }
      else {
        const res = await editSampleUpdatedVersion(mode.token, selectedSample.id, selectedSample);

        if (res.success) {
          setWidget({
            status: 'Ok',
            text: 'Образец успешно добавлен!',
            fun: async () => {
              mode.setType({mode: 'view', item: ''});

            }
          });

          let sample = JSON.parse(JSON.stringify(selectedSample));

          if (mode.selectedCell.length !== 0) {
            const cells = mode.selectedCell;
            for(let i=0; i<cells.length; i++) {
              let cell = cells[i];
              sample.column= cell.column;
              sample.line= cell.line;
              sample.storage=cell.storageId;
              sample.pack= cell.packId;
              sample.barcodes =[];
              sample.id = null;

              await editSampleUpdatedVersion(mode.token, sample.id, sample);
            }
            mode.setSelectedCell([]);
          }
          await loadTree(mode.token, false, '');
          await updateSelectedTreeNodePacks(mode.token);
          clearSelectedSample();
          mode.setVisibleAdd(false);

        }else if(res.error_code == 2029)
        {
          setErrorBarcode(true)
        }
        else {
          setWidget({
            status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
            text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
            fun: async () => {
            }
          });
        }
      }
    }
  }

  const onClose = () => {
    clearSelectedSample();
    setVisibleAdd(false);
  }

  const resetSelectItem = () => {
    setVisibleAdd(false);
    setNewUserFields({id:0, fields:{}});
  }

  return (
    <div className={dropStyles.addMenu_block_sample} style={{zIndex: 2}}>
      <div>
        <div className={dropStyles.title}>
          <div>Образец</div>
          <button className={dropStyles.closeButton}
                  onClick={onClose}>
            <img src={closeSVG} alt=""/>
          </button>
        </div>
        <div className={dropStyles.info}>
          {
            (!selectedSample?.id && mode.selectedCell.length !== 0) &&
            <div className={dropStyles.infoItem}
                 style={{marginBottom: '8px'}}>
              <p className={dropStyles.infoItemName}>Групповое создание</p>
              <div className={dropStyles.infoItemInput}
                   style={{alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                Количество: {mode.selectedCell.length}
                <button className={dropStyles.infoItemArrow}
                        onClick={() => mode.setSelectedCell([])}>
                  <img src={deleteSvG} alt=""/>
                </button>
              </div>
            </div>
          }

          <div className={dropStyles.infoItem} style={{marginBottom: '8px'}}>
            <p className={dropStyles.infoItemName}>Тип</p>
            <div className={dropStyles.infoItemInput}>
              {selectedType?.name ?? ''}
            </div>
            {
              (selectedType && selectedType !== {}) ?
                <div className={dropStyles.infoItemType}
                     style={{
                       background: selectedType?.color ?? '#FFFFFF'
                     }}>
                  <img src={`${address_server_short}/${selectedType?.icon}`} className={classes.imgType} alt={'error'}/>
                </div> :
                <div className={dropStyles.infoItemType} style={{opacity: 0}}></div>
            }
          </div>

          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Наименование</p>
            <div className={dropStyles.infoItemInput}>
              <input className={dropStyles.infoItemValue + ' ' + (nameError && dropStyles.errorInput)}
                     value={selectedSample?.name == null || selectedSample?.name == 'null' ? '' : selectedSample?.name}
                     onChange={e => {
                       setSampleField('name', e.target.value);
                     }}/>
            </div>
          </div>

          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Расположение</p>
            <div className={dropStyles.infoItemInput}>
              <input className={dropStyles.infoItemValue}
                     value={path}
                     readOnly={true}
                     onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}/>
              <button className={dropStyles.infoItemArrow}
                      onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}><img
                src={arrowSVG} alt=""/></button>
              {
                isVisiblePositionDropdown &&
                <>
                  <div className={dropStyles.DropdownBlockAll}
                       onClick={() => setIsVisiblePositionDropdown(false)}></div>
                  <div className={dropStyles.TreeBlock}>
                    <Tree
                      storages={treeLocation}
                      setVisibleTreeLocation={setIsVisiblePositionDropdown}
                      setLocation={(val) => {
                        setSampleField('storage', val.id);
                        setLocationError(false);
                      }}/>
                  </div>
                </>
              }
              {
                locationError &&
                <div className={classes.dataErrorBlock}>
                  <img src={error} alt={'error'}/>
                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                </div>
              }
            </div>
          </div>

          {
            packs.length ?
              <>
                <div className={dropStyles.infoItem}>
                  <p className={dropStyles.infoItemName}>Штатив</p>
                  <DropdownComponent key={'packs_1'}
                                     items={packs}
                                     selectedKey={selectedSample?.pack}
                                     onChange={(val) => setSampleField('pack', val)} />
                </div>
                <div className={dropStyles.infoItem}
                     style={{marginBottom: "30px"}}>
                  <p className={dropStyles.infoItemName}>Место хранения</p>
                  <div style={{width: '200px', display: 'flex', alignItems: 'center', gap: '10px'}}>
                    {
                      selectedPack && <DropdownComponent key={'line_1'}
                                         items={selectedPack?.structura?.lines &&
                                           selectedPack?.structura?.lines.map(i => ({id: i, name: i}))}
                                         selectedKey={selectedSample?.line}
                                         disabled={!selectedSample?.pack}
                                         onChange={(val) => setSampleField('line', val)} />
                    }
                    {
                      selectedPack && <DropdownComponent key={'column_1'}
                                         items={selectedPack?.structura?.columns &&
                                           selectedPack?.structura?.columns.map(i => ({id: i, name: i}))}
                                         selectedKey={selectedSample?.column}
                                         disabled={!selectedSample?.pack}
                                         onChange={(val) => setSampleField('column', val)} />
                    }
                  </div>
                </div>
              </> : <></>
          }

          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Штрихкоды</p>
            <div className={dropStyles.infoItemInput}>
              <BarcodesMultiList list={selectedSample?.barcodes ?? ['']}
                                 getItemsList={getListBarcodes}
                                 setWidget={setWidget}
                                 setList={(val) => setSampleField('barcodes', val)} />
              {
                  errorBarcode &&
                  <div className={classes.dataErrorBlock}>
                    <img src={error} alt={'error'}/>
                    <div className={classes.dataError}>Штрихкод должен быть уникальным</div>
                  </div>
              }
            </div>
          </div>


          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Количество образца</p>
            <div className={dropStyles.infoItemInput}>
              <input className={dropStyles.infoItemValue}
                     value={selectedSample?.volume ?? ''}
                     type={'number'}
                     onWheel={(e) => e.target.blur()}
                     onChange={(e) => {
                       setSampleField('volume', e.target.value)
                       setVolumeError(false)
                     }}/>
              {
                volumeError &&
                <div className={classes.dataErrorBlock}>
                  <img src={error} alt={'error'}/>
                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                </div>
              }
            </div>
          </div>

          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Единицы измерения</p>
            <div className={dropStyles.infoItemInput + ' ' + dropStyles.centerTextVertical}>
              <div>{unitsList.find(val => val.id == selectedType?.unit)?.name}</div>
            </div>
          </div>

          <div className={dropStyles.infoItem}>
            <p className={dropStyles.infoItemName}>Дата забора материала</p>
            <div className={dropStyles.infoItemInput}>
              <WidgetDateTimePicker selected={selectedSample?.date ?? ''}
                                    onChange={(val) => {
                                      setDateError(false);
                                      setSampleField('date', val)
                                    }}
                                    isDateTime={true} />
              {
                dateError &&
                <div className={classes.dataErrorBlock}>
                  <img src={error} alt={'error'}/>
                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                </div>
              }
            </div>
          </div>
          <div className={dropStyles.description}>
            <div>Описание</div>
            <textarea className={dropStyles.textarea}
                      placeholder={'Описание'}
                      disabled={true}
                      value={selectedSample?.description ?? selectedType?.description ??  ''}
                      onChange={(e) => {
                        setDescError(false);
                        setSampleField('description', e.target.value)
                      }}></textarea>

            {
                descError &&
                <div className={classes.dataErrorBlock}>
                  <img src={error} alt={'error'}/>
                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                </div>
            }
          </div>
          {
              types
              && types.find(val => val.id == selectedSample?.type)
              && types.find(val => val.id == selectedSample?.type).user_fields
              &&
              <div className={classes.separatingRow}>
                Пользовательские поля
              </div>
          }
          {
            types && types.find(val => val.id == selectedSample?.type) &&
              types.find(val => val.id == selectedSample?.type)?.user_fields.map((val, index) => {

                    const handlerInputData = (data) => {
                      const newSelectItem = {...newUserFields};
                      newSelectItem.fields[val.id_field] = data;
                      setNewUserFields(newSelectItem)
                    }
                    const handlerInputFile = (files) => {
                      const newSelectItem = {...newUserFields};
                      newSelectItem[`files[${val.id_field}]`] = files;
                      setNewUserFields(newSelectItem)
                    }

                    let value = newUserFields.fields[val.id_field] || '';

                    return <>
                      <WorkRightAddMenuItem
                          item={{type: val.type.type_name, name: val.name, setting: JSON.parse(val.setting), id: val.id_field}}
                          selectItem={value}
                          resetSelectItem={resetSelectItem}
                          setWidget={setWidget}
                          setNewSelectItem={handlerInputData}
                          setFiles={handlerInputFile}
                          key={index}
                          isUserFieldsOperation={true}
                      />
                      {
                        clickOnSave
                            ? val.required
                              ? newUserFields.fields[val.id_field]
                                ? newUserFields.fields[val.id_field].value == ''
                                  && newUserFields.fields[val.id_field].files?.length == 0
                                  && clickOnSave
                                  &&
                                  <div className={classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc}>
                                    <img src={error} alt={'error'}/>
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                  </div>
                                :
                                  <div className={classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc}>
                                    <img src={error} alt={'error'}/>
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                  </div>
                              :<></>
                            :<></>
                      }
                    </>
                }
              )
          }
          <div className={classes.separatingRow}>
            Дополнительная информация
          </div>
          <div>
            <MultiSelectBoxes dataSource={catalogsDataSource}
                              result={toJS(patientDescriptions)}
                              setWidget={setWidget}
                              clickOnSave={clickOnSave}
                              mode={mode}
                              showDependentElements={true}
                              setResult={setPatientDescriptions}/>
          </div>
          <div className={dropStyles.functionalButtons}>
            <div className={dropStyles.save} onClick={startSave}>Сохранить</div>
            <div className={dropStyles.cancel} onClick={onClose}>Отмена
          </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AddSampleModal;
