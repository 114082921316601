import React, {useEffect, useState} from 'react';
import classes from "./workRightTable.module.css";
import triangle from '../../../../svg/triangle-01.svg'

const WorkRightTableHeader = ({data, sortField, setSortField, count}) => {

    const [w, setW] = useState(100 / count);
    useEffect(()=>{
        if(data.type === 'image')
            setW(5)
        else{
            setW(100 / count)
        }
    }, [count])
    return (
        <>
            {
                data.name == 'selectToPrint'
                    ? <>
                        <td className={classes.workRightTableHeader} width={'2% !important' }>
                        </td>
                    </>
                    : <td className={classes.workRightTableHeader} width={w+'%'}>
                        <div className={classes.workRightTableHeaderBlock}>
                            <div>{data.name}</div>
                            {data.sort &&
                                (sortField.field !== data.field ? <div className={classes.workRightTableSortBlock}>
                                        <div className={classes.workRightTableSortUpNot} onClick={()=>setSortField({type:'desc',field:data.field})}/>
                                        <div className={classes.workRightTableSortDownNot} onClick={()=>setSortField({type:'asc',field:data.field})}/>
                                    </div> :
                                    (sortField.type === 'asc' ? <div className={classes.workRightTableSortDown} onClick={()=>setSortField({type:'',field:''})}></div> :
                                        <div className={classes.workRightTableSortUp} onClick={()=>setSortField({type:'',field:''})}></div>))
                            }
                        </div>
                    </td>
            }
        </>


    );
};

export default WorkRightTableHeader;
