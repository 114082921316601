import LeftMenu from "./components/LeftMenu/leftMenu";
import Header from "./components/Header/header";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import Path from "./components/Path/Path";
import Work from "./components/Work/work";
import Authorization from "./components/Authorization/authorization";
import Widget from "./components/Widgets/widget";
import { getCookie, setCookie } from "./utils/fun_cookie";
import WrapContext from "./components/Contexts/WrapContext";
import { modeContext } from "./components/Contexts/contexts";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import "./app.css";
import "./bootstrap.css"
import TabsPages from "./components/TabPages/TabPages";
import {StructureStore} from "./store/StructureStore";
import {StorageStore} from "./store/StorageStore";
import {SamplesStore} from "./store/SamplesStore";
import {FieldsStore} from "./store/FieldsStore";
import {Store} from './store/Store.js'
import {ModeMove} from "./components/Widgets/Modal/ModalMoveComponent";
import {getLicenseInfo} from "./utils/API/api_license";
import React from 'react';
import {copySample, moveSample} from "./utils/API/api_sample";
import {getRules} from "./utils/API/api_auth";
import {TypeSampleStore} from "./store/TypeSampleStore";
import SelectCopyTypeModal from "./helpers/selectCopyTypeModal";

export const OutsideContext = createContext(null);
export const CtrlCMode = 0;//Копировать
export const CtrlXMode = 1;//Переместить

function App() {
  const [token, setToken] = useState('');
  const [rules, setRules] = React.useState();

  const [isVisibleDropdown, setVisibleDropdown] = useState(false);
  const [menu, setMenu] = useState([]);
  const [showEmptyDetail, setShowEmptyDetail] = React.useState(false);
    const [licenseInfo, setLicenseInfo] = useState(null);
    const [fullMenu, setFullMenu] = useState(true)
    const [loginMenu, setLoginMenu] = useState(false)
    const [widget, setWidget] = useState({ status: '', text: '', fun: null })
    const [path, setPath] = useState('')
    const [type, setType] = useState({ mode: 'view', item: '' })
    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    const [viewMode, setViewMode] = useState(0);
    const [copyMode, setCopyMode] = useState(false);
    const [copyType, setCopyType] = useState(ModeMove);
    const [puckAddModal, setPuckAddModal] = useState(false);
    const [puckSelectModal, setPuckSelectModal] = useState(false);
    const rootClasses = ['Right_block'];
    const [move, setMove] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const advancedSettings = true;
    const [selectedCell, setSelectedCell] = useState([]);
    const [sharedFilter, setSharedFilter] = useState(false);

    const [selectedSamplesForCopy, setSelectedSamplesForCopy] = React.useState([]);
    const [selectedEmptyCellForCopy, setSelectedEmptyCellForCopy] = React.useState([]);
    const [selectedCopyMode, setSelectedCopyMode] = React.useState(CtrlCMode);
    const [viewNewSampleTypeModal, setViewNewSampleTypeModal] = React.useState(false);
    const [isOpenSelectCopyTypeModel, setIsOpenSelectCopyTypeModel] = React.useState(false);

    const [tabs, setTabs] = useState([]);
    const [activeTabId, setActiveTabId] = useState(0);

    const editSelectedCell = (cell) => {
      const array = JSON.parse(JSON.stringify(selectedCell));
      let item = array.find(
        i => i.packId === cell.packId
          && i.column === cell.column
          && i.line === cell.line
          && i.storageId === cell.storageId);

      if (item) {
        let index = array.indexOf(item);
        array.splice(index, 1);
      } else {
        array.push(cell);
      }

      setSelectedCell(array);
    }

    const structureStore = useContext(StructureStore);
    const storageStore = useContext(StorageStore);
    const sampleStore = useContext(SamplesStore);
    const fieldsStore = useContext(FieldsStore);
    const store = useContext(Store)
    const typeSampleStore = useContext(TypeSampleStore)

    const setModeByTab = (index) => {
      const tab = tabs[index];

      if (tab) {
          //localStorage.setItem('active_tabs', tab.id);
          const tabMode = tab.mode.mode;

          if (tab.path === 'Справочники' && tabMode.type.mode === 'view') {
            structureStore.setSelectedStructure(tabMode.type.item.id);
          }

          storageStore.setStore(tab.storageStore);
          structureStore.setStore(tab.structureStore);
          sampleStore.setStore(tab.sampleStore);
          fieldsStore.setStore(tab.fieldsStore);
          store.setStore(tab.store);
          typeSampleStore.setStore(tab.typeSampleStore);

          setPath(tab.path);
          setType(tabMode.type);
          localStorage.setItem("save_path", JSON.stringify(tab.path));
          localStorage.setItem("save_type", JSON.stringify(tabMode.type));
          setVisibleAdd(tabMode.visibleAdd);
          setVisibleView(tabMode.visibleView);
          setShowEmptyDetail(tabMode.showEmptyDetail);
          setCopyMode(tabMode.copyMode);
          setViewMode(tabMode.viewMode);
          setPuckAddModal(tabMode.puckAddModal);
          setPuckSelectModal(tabMode.puckSelectModal);
          setSelectedCell(tabMode.selectedCell);
      }
    }

    const setModeByParseTab = (tab) => {

    if (tab) {
      //localStorage.setItem('active_tabs', tab.id);
      const tabMode = tab.mode.mode;
/*
      if (tab.path === 'Справочники' && tabMode.type.mode === 'view') {
        structureStore.setSelectedStructure(tabMode.type.item.id);
      }*/

      storageStore.setStore(tab.storageStore);
      structureStore.setStore(tab.structureStore);
      sampleStore.setStore(tab.sampleStore);
      fieldsStore.setStore(tab.fieldsStore);
      store.setStore(tab.store);
      typeSampleStore.setStore(tab.typeSampleStore);

      setPath(tab.path);
      setType(tabMode.type);
      localStorage.setItem("save_path", JSON.stringify(tab.path));
      localStorage.setItem("save_type", JSON.stringify(tabMode.type));
      setVisibleAdd(tabMode.visibleAdd);
      setVisibleView(tabMode.visibleView);
      setShowEmptyDetail(tabMode.showEmptyDetail);
      setCopyMode(tabMode.copyMode);
      setViewMode(tabMode.viewMode);
      setPuckAddModal(tabMode.puckAddModal);
      setPuckSelectModal(tabMode.puckSelectModal);
      setSelectedCell(tabMode.selectedCell);
    }
  }

    useEffect(() => {
      updateMode();
      //localStorage.setItem('tabs', JSON.stringify(tabs));
    }, [path, type, visibleAdd, copyMode, store, typeSampleStore.typeSampleSelected])

    const updateMode = () => {
      const updatedTabs = [...tabs];

      const tab = updatedTabs.find(item => item.id === activeTabId);

      if (tab) {
        tab.path = path;
        tab.name = path;

        tab.mode = JSON.parse(JSON.stringify({ mode:
            {   path,
              type,
              token,
              visibleAdd,
              visibleView,
              showEmptyDetail,
              copyMode,
              viewMode,
              puckAddModal,
              puckSelectModal,
              selectedCell,
            },
          widget:
            {
              widget,
            },
          rules: {
            advancedSettings: advancedSettings
          }}));

        tab.storageStore = JSON.parse(JSON.stringify(storageStore.getStore()));
        tab.structureStore = JSON.parse(JSON.stringify(structureStore.getStore()));
        tab.sampleStore = JSON.parse(JSON.stringify(sampleStore.getStore()));
        tab.fieldsStore = JSON.parse(JSON.stringify(fieldsStore.getStore()));
        tab.store = JSON.parse(JSON.stringify(store.getStore()));
        tab.typeSampleStore = JSON.parse(JSON.stringify(typeSampleStore.getStore()))
      }
      setTabs(updatedTabs);
    }

    const setPathMiddleware = (data) => {
        localStorage.setItem("save_path", JSON.stringify(data));
        setPath(data);

    }

    const loadPathMiddleware = () => {
        const str_data = localStorage.getItem("save_path");

        const data = JSON.parse(str_data);
        setPath(data);
        const tabsStr = localStorage.getItem('tabs');
        if (data) {
          if (tabsStr === null || tabsStr?.length === 0) {
            const newTab = {
              id: new Date().getTime(),
              name: data,
              path: data,
              mode: JSON.parse(JSON.stringify({ mode:
                  {   path,
                    type,
                    token,
                    visibleAdd,
                    copyMode,
                    showEmptyDetail,
                    viewMode,
                    puckAddModal,
                    puckSelectModal,
                    selectedCell,
                  },
                widget:
                  {
                    widget
                  },
                rules: {
                  advancedSettings: advancedSettings
                }})),
              storageStore: JSON.parse(JSON.stringify(storageStore.getStore())),
              structureStore: JSON.parse(JSON.stringify(structureStore.getStore())),
              sampleStore: JSON.parse(JSON.stringify(sampleStore.getStore())),
              fieldsStore: JSON.parse(JSON.stringify(fieldsStore.getStore())),
              store: JSON.parse(JSON.stringify(store.getStore())),
                typeSampleStore: JSON.parse(JSON.stringify(typeSampleStore.getStore())),
            };
            setTabs([newTab]);
            setActiveTabId(newTab.id);
          } else {
            const parseTabs = JSON.parse(tabsStr);
            let currentActiveTab = localStorage.getItem('active_tabs');

            setTabs(parseTabs);
            if(currentActiveTab) {
              let tab = parseTabs.find(i => i.id == currentActiveTab);
          
              if (tab) {
                setActiveTabId(tab.id);
                setModeByParseTab(tab);
              }

            } else if (parseTabs[0]) {
              setActiveTabId(parseTabs[0].id);
              setModeByParseTab(parseTabs[0]);
            }
          }
        }
    }

    const setTypeMiddleware = (data) => {
        localStorage.setItem("save_type", JSON.stringify(data));
        setType(data)
    }

    const loadTypeMiddleware = () => {
        const str_data = localStorage.getItem("save_type");
        try{

          const data = JSON.parse(str_data);

          setType(data);
        } catch {}
    }

    useEffect(() => {
      const loadLicense = async () => {
        const res = await getLicenseInfo(token);
        if (res.success)
        {
          if (res.result) {
            setLicenseInfo(res.result);
                      }
          else {
            setLicenseInfo(null);
          }
        }

      }
      loadLicense();
    }, [token]);

    useEffect(() => {
      const menu = [ ];
      if (licenseInfo?.extension) {
        menu.push({ id: 1, text: 'Проекты', src: 'projects-01.svg' });
      }

      if (Object.values(rules?.storage ?? []).filter(i => i).length > 0)
        menu.push({ id: 2, text: 'Хранилище', src: 'storage-01.svg' });

      if (Object.values(rules?.sample ?? []).filter(i => i).length > 0)
        menu.push({ id: 3, text: 'Образцы', src: 'samples-01.svg' });

      if (Object.values(rules?.report ?? []).filter(i => i).length > 0 || Object.values(rules?.report_group ?? []).filter(i => i).length > 0)
        menu.push( { id: 4, text: 'Отчеты', src: 'reports-01.svg' });

      if (Object.values(rules?.catalog ?? []).filter(i => i).length > 0)
        menu.push(  { id: 5, text: 'Справочники', src: 'manual-01.svg' });

      // if (Object.values(rules?.storage ?? []).filter(i => i).length > 0)
      menu.push({ id: 6, text: 'Импорт', src: 'import_export-01.svg'});

      if (Object.values(rules?.setting ?? []).filter(i => i).length > 0)
        menu.push({ id: 8, text: 'Настройки системы', src: 'settings_system-01.svg' });

      setMenu(menu);

    }, [licenseInfo, rules]);

    if (!fullMenu) {
        rootClasses.push('Right_block_small');
    }

    useEffect(() => {

        const token_c = getCookie('token')
        if (token_c) {
          setToken(token_c);

        }
        if (window.location.pathname === '/recovery') {
            setToken('');
            setLicenseInfo(null);
            setCookie('token', '')
        }

      loadPathMiddleware();
      loadTypeMiddleware();


    }, []);

    React.useEffect(() => {
      const getRulsForUser = async () => {
        let res = await getRules(token);
        setRules(res);
      }

      getRulsForUser();
    }, [token]);

    useEffect(() => {
      localStorage.setItem('tabs', JSON.stringify(tabs));
    }, [tabs]);

    React.useEffect(() => {
    document.addEventListener('keydown', pasteSamplesToSelectedCells);

    return () => {
      document.removeEventListener('keydown', pasteSamplesToSelectedCells);
    }
    });

    const pasteSamplesToSelectedCells = async (e) => {

      let key = e.which || e.keyCode;
      let ctrl = e.ctrlKey ? e.ctrlKey : (key === 17);

      if (ctrl && key === 86) {
        if (selectedEmptyCellForCopy.length > 0 && selectedSamplesForCopy.length > 0) {

          //move samples
          if (selectedCopyMode === CtrlXMode) {
            selectedEmptyCellForCopy.map(async (item, index) => {
              let sample = selectedSamplesForCopy[index];
              if (sample) {
                let settings = {
                  id: sample,
                  volume: null,
                  new_storage: item.storageId ?? 0,
                  new_pack: item.packId,
                  column: item.column,
                  line: item.line
                };

                await moveSample(token, item.sampleId, settings);
              }
            });
            await store.loadTree(token, false, '');
            await store.updateSelectedTreeNodePacks(token);
          }

          //copy samples
          if (selectedCopyMode === CtrlCMode) {

              setIsOpenSelectCopyTypeModel(true)
              return

          }

          setSelectedEmptyCellForCopy([]);
          setSelectedSamplesForCopy([]);
        }
      }
      else if(ctrl && key === 88){
          setSelectedCopyMode(1)
      }
    }

    const execCopy = async (type) => {
        selectedSamplesForCopy.map(async val => {
            await copySample(token, val, selectedEmptyCellForCopy, type)
        })

        await store.loadTree(token, false, '');
        await store.updateSelectedTreeNodePacks(token);

        setSelectedEmptyCellForCopy([]);
        setSelectedSamplesForCopy([]);
    }

  const containerRef = useRef(null);
    return (
        <div className="App"
             ref={containerRef}
             onClick={() => {
                  setVisibleDropdown(false);
                  setViewMode(0) }}>
          <OutsideContext.Provider value={ containerRef }>
            <DndProvider backend={HTML5Backend}>
                <modeContext.Provider value={{ mode:
                      {   path,
                          setPath: setPathMiddleware,
                          type,
                          setType: setTypeMiddleware,
                          token,
                          rules,
                          setToken,
                          visibleAdd,
                          setVisibleAdd,
                          visibleView,
                          setVisibleView,
                          copyMode,
                          setCopyMode,
                          viewMode,
                          copyType, setCopyType,
                          setViewMode,
                          updateMode,
                          puckAddModal,
                          setPuckAddModal,
                          puckSelectModal,
                          sharedFilter,
                          setSharedFilter,
                          setPuckSelectModal,
                          setMove,
                          isDragging,
                          selectedSamplesForCopy,
                          setSelectedSamplesForCopy,
                          setIsDragging,
                          showEmptyDetail,
                          setShowEmptyDetail,
                          selectedCell,
                          activeTabId,
                          setActiveTabId,
                          licenseInfo,
                          setLicenseInfo,
                          editSelectedCell,
                          viewNewSampleTypeModal,
                          setViewNewSampleTypeModal,
                          setSelectedCell,
                          tabs,
                          setTabs,
                          setModeByTab,
                          selectedCopyMode,
                          setSelectedCopyMode,
                          selectedEmptyCellForCopy,
                          setSelectedEmptyCellForCopy,
                          setModeByParseTab
                      },
                      widget:
                        {
                            widget,
                            setWidget
                        },
                    rules: {
                      advancedSettings: advancedSettings
                    }}}>
                    {token !== '' && <LeftMenu fullMenu={fullMenu} menu={menu} token={token}/>}
                    {token !== '' && <div className={rootClasses.join(' ')}>
                        <Header fullMenu={fullMenu} setFullMenu={setFullMenu} setLoginMenu={setLoginMenu} isVisibleDropdown={isVisibleDropdown} setVisibleDropdown={setVisibleDropdown} />
                        <Path path={path} />
                        <TabsPages />
                        <WrapContext setFullMenu={setFullMenu}>
                            <Work fullMenu={fullMenu} />
                        </WrapContext>
                    </div>}
                    {(token === '' || loginMenu) && <Authorization setLoginMenu={setLoginMenu} setToken={setToken} />}
                    {widget.status !== "" && <Widget widget={widget} setWidget={setWidget} />}
                </modeContext.Provider>
            </DndProvider>
          </OutsideContext.Provider>
            {
                isOpenSelectCopyTypeModel && <SelectCopyTypeModal setModalOpened={setIsOpenSelectCopyTypeModel} execCopy={execCopy}/>
            }
        </div>
    );
}

export default App;
